<template>
  <div class="w-full">
    <table
      cellspacing="0"
      class="w-full pdf_table overflow-hidden"
      style="font-family: Roboto; font-size: 6px"
    >
      <tr style="background: #6cb9f4">
        <th colspan="4">
          <p class="py-1.5 relative">
            <b>АО УЗБЕКИСТОН ТЕМИР ЙУЛЛАРИ</b>
            <img
              class="absolute right-5 top-1/2 h-5/6 transform -translate-y-1/2"
              src="@/assets/img/logopdf.jpg"
              alt=""
            />
          </p>
        </th>
      </tr>
      <tr style="background: #dddddd; color: red">
        <th colspan="3" class="w-4/5 border-r border-black">
          <div class="flex flex-wrap justify-center -mx-3">
            <p class="px-3">
              <b
                >ВИД ТЕХ.ОБСЛ - <u>{{ corrective_type.name }}</u></b
              >
            </p>
            <p class="px-3">
              <b
                >№ ПОЕЗДА: <u>{{ inspection.train.name }}</u></b
              >
            </p>
          </div>
        </th>
        <th style="width: 13%">
          <b
            >Дата создания <br />
            {{ inspection.created_time.split("T").shift() }}</b
          >
        </th>
      </tr>
      <tr style="background: #d9dbf0; color: #414bb2">
        <th colspan="3" class="w-4/5 border-r border-black">
          <p class="py-1.5">
            <b><u>{{ inspection.sample.name }}</u></b>
          </p>
        </th>
        <th style="width: 13%">
          <b>Страница</b>
        </th>
      </tr>
      <tr>
        <th class="border-r border-black" style="width: 8%">
          <p>
            Код п.т. <br /><b
              ><u>{{ inspection.code }}</u></b
            >
          </p>
        </th>
        <th class="border-r border-black">
          <p>
            № РЕГИТСТРА -
            <b
              ><u>{{ inspection.number }}</u></b
            >
          </p>
        </th>
        <th class="w-1/4 border-r border-black">
          тип тех обслуживания:
          <b
            ><u>{{
              inspection.sample.type == 10
                ? "PREVENTIVE"
                : inspection.sample.type == 9
                ? "CORRECTIVE"
                : "VANDALISM"
            }}</u></b
          >
        </th>
        <th style="width: 15%">
          NTM: <b><u>{{ sample.instruction.name }} </u></b>
        </th>
      </tr>
      <tr style="background: #c6c6c6">
        <td colspan="4" class="text-center py-1">
          <b>ВЫПОЛНЯЕМЫЕ ОПЕРАЦИИ</b>
        </td>
      </tr>
      <tr>
        <td class="text-center border-r border-black">№</td>
        <td class="text-center border-r border-black">
          <p class="py-1">ОПИСАНИЕ РАБОТ</p>
        </td>
        <td class="text-center border-r border-black">Место нахожд</td>
        <td class="text-center">Подпись исп. / Дата</td>
      </tr>
      <tr v-for="(task_result, index) in task_results" :key="index">
        <td class="text-center border-r border-black">{{ index + 1 }}</td>
        <td class="border-r border-black">
          <p class="p-1">
            <b>{{ task_result.task.name }}</b> <br />
            <i>{{ task_result.task.description }}</i>
          </p>
        </td>
        <td class="text-center border-r border-black">
          {{ task_result.task.location }}
        </td>
        <td>
          <div
            class="relative flex justify-center items-center overflow-hidden"
            style="min-height: 30px"
            v-if="task_result.logs.length > 1"
          >
            <p class="text-center relative z-10">
              {{ task_result.logs[1].user }}
              <br />
              {{ task_result.logs[1].created_time.split("T").shift() }} /
              {{
                task_result.logs[1].created_time
                  .split("T")
                  .pop()
                  .split(".")
                  .shift()
                  .slice(0, 5)
              }}
            </p>
            <img
              class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              style="max-width: 80%; max-height: 80%;"
              src="@/assets/img/logo2.png"
              alt=""
            />
          </div>
          <div v-else>
            <div
              class="border-2 border-red-500 flex justify-center items-center w-max mx-auto p-1 text-red-500 font-medium"
            >
              не подписан
            </div>
          </div>
        </td>
      </tr>
      <tr style="background: #fde99f">
        <td colspan="4" class="text-center py-1">
          <b>ВЫПОЛНЕННЫЕ ОПЕРАЦИИ НЕ ВХОДЯЩИЙ В ПЛАН ТЕХ.ОБСЛУЖИВАНИЯ</b>
        </td>
      </tr>
      <tr>
        <td class="text-center border-r border-black">№ АВАРИИ</td>
        <td colspan="2" class="text-center border-r border-black">
          <p class="py-1">ВЫПОЛНЕННЫЙ РЕМОНТ</p>
        </td>
        <td class="text-center">Подпись исп. / Дата</td>
      </tr>
      <tr v-for="(repair_list, index) in repairs_list" :key="index">
        <td class="text-center border-r border-black">{{ index + 1 }}</td>
        <td colspan="2" class="border-r border-black">
          <p class="p-1">
            <b>Код аварии - {{ repair_list.crash_code }}</b> <br />
            <i>{{ repair_list.comment }}</i>
          </p>
        </td>
        <td>
          <div
            class="relative flex justify-center items-center overflow-hidden"
            style="min-height: 30px"
          >
            <p class="text-center relative z-10">
              <!-- {{ repair_list }} -->
              <br />
              {{ repair_list.created_time.split("T").shift() }} /
              {{
                repair_list.created_time
                  .split("T")
                  .pop()
                  .split(".")
                  .shift()
                  .slice(0, 5)
              }}
            </p>
            <img
              class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              style="max-width: 80%; max-height: 80%;"
              src="@/assets/img/logo2.png"
              alt=""
            />
          </div>
        </td>
      </tr>
      <tr v-if="!repairs_list.length">
        <td colspan="4">
          <p class="my-3 text-center text-red-500 font-bold">
            НЕТ ВЫПОЛНЕННЫХ РЕМОНТОВ
          </p>
        </td>
      </tr>

      <tr style="background: #fde99f">
        <td colspan="4" class="text-center py-1">
          <b>КОММЕНТАРИИ ПО ТЕХ ОБСЛУЖИВАНИЮ</b>
        </td>
      </tr>
      <tr>
        <td class="text-center border-r border-black">№</td>
        <td colspan="2" class="text-center border-r border-black">
          <p class="py-1">КОММЕНТАРИЙ</p>
        </td>
        <td class="text-center">Подпись исп. / Дата</td>
      </tr>
      <tr v-for="(comment, index) in comments" :key="comment.id">
        <td class="text-center border-r border-black">{{ index + 1 }}</td>
        <td colspan="2" class="border-r border-black">
          <p class="p-1">
            <i>{{ comment.text }}</i>
          </p>
        </td>
        <td>
          <div
            class="relative flex justify-center items-center overflow-hidden"
            style="min-height: 30px"
          >
            <p class="text-center relative z-10">
              {{ comment.user.full_name }}
              <br />
              {{ comment.created_time.split("T").shift() }} /
              {{
                comment.created_time
                  .split("T")
                  .pop()
                  .split(".")
                  .shift()
                  .slice(0, 5)
              }}
            </p>
            <img
              class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              style="max-width: 80%; max-height: 80%;"
              src="@/assets/img/logo2.png"
              alt=""
            />
          </div>
        </td>
      </tr>
      <tr v-if="!comments.length">
        <td colspan="4">
          <p class="my-3 text-center text-red-500 font-bold">
            НЕТ КОММЕНТАРИЕВ
          </p>
        </td>
      </tr>

      <tr
        v-if="inspection.is_closed || inspection.is_signed"
        style="background: #fde99f"
      >
        <td colspan="4" class="text-center py-1">
          <b>ЗАВЕРШЕНИЕ ТЕХНИЧЕСКОГО ОБСЛУЖИВАНИЯ</b>
        </td>
      </tr>
      <tr v-if="inspection.is_closed || inspection.is_signed">
        <td class="text-center border-r border-black">№</td>
        <td colspan="2" class="text-center border-r border-black">
          <p class="py-1">ОТВЕТСТВЕННЫЕ ЛИЦА</p>
        </td>
        <td class="text-center">Подпись исп. / Дата</td>
      </tr>
      <tr v-if="inspection.is_closed">
        <td class="text-center border-r border-black">1</td>
        <td colspan="2" class="text-center border-r border-black">
          <p>
            <b>{{ inspection.responsible_person.full_name }}</b
            ><br /><i>НАЧАЛЬНИК СМЕНЫ</i>
          </p>
        </td>
        <td>
          <div
            class="relative flex justify-center items-center overflow-hidden"
            style="min-height: 30px"
          >
            <p class="text-center relative z-10">
              {{ inspection.responsible_person.full_name }}
              <br />
              {{ inspection.created_time.split("T").shift() }} /
              {{
                inspection.created_time
                  .split("T")
                  .pop()
                  .split(".")
                  .shift()
                  .slice(0, 5)
              }}
            </p>
            <img
              class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              style="max-width: 80%; max-height: 80%;"
              src="@/assets/img/logo2.png"
              alt=""
            />
          </div>
        </td>
      </tr>
      <tr v-if="inspection.is_signed">
        <td class="text-center border-r border-black">
          {{ inspection.is_closed ? 2 : 1 }}
        </td>
        <td colspan="2" class="text-center border-r border-black">
          <p>
            <b>{{ inspection.author.full_name }}</b
            ><br /><i>АДМИНИСТРАЦИЯ</i>
          </p>
        </td>
        <td>
          <div
            class="relative flex justify-center items-center overflow-hidden"
            style="min-height: 30px"
          >
            <p class="text-center relative z-10">
              {{ inspection.author.full_name }}
              <br />
              {{ inspection.created_time.split("T").shift() }} /
              {{
                inspection.created_time
                  .split("T")
                  .pop()
                  .split(".")
                  .shift()
                  .slice(0, 5)
              }}
            </p>
            <img
              class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              style="max-width: 80%; max-height: 80%;"
              src="@/assets/img/logo2.png"
              alt=""
            />
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "PdfFile",
  props: ["inspection", "comments", "repairs_list", "task_results", "corrective_type", "sample"],
};
</script>

<style lang="scss" scoped></style>
