<template>
  <div class="flex justify-center items-center w-6 h-6">
    <component :is="dynamicIcon" />
  </div>
</template>

<script>
export default {
  name: "DynamicIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    dynamicIcon() {
      return () => import(`../assets/svg/${this.icon}.svg`);
    },
  },
};
</script>
